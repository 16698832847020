import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <label className="poppins">
        <span>Poppins</span>
        <h1>Transport App</h1>
      </label>
      <label className="open-sans">
        <span>open sans</span>
        <h1>Transport App</h1>
      </label>
    </div>
  );
}

export default App;
